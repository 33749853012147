import { DOCUMENT } from '@angular/common';
import { Injectable, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SeoService {
    private readonly document = inject(DOCUMENT);
    private readonly title = inject(Title);
    private readonly meta = inject(Meta);
    private readonly router = inject(Router);

    constructor() {
        const routerEvents = toSignal(this.router.events);
        effect(() => {
            const event = routerEvents();
            if (event instanceof NavigationEnd) {
                this.meta.updateTag({
                    property: 'og:url',
                    content: 'https://' + this.document.location.hostname + this.router.url
                });
            }
        });
    }

    updateTags(seoData: {
        title: string;
        description: string;
        ogDescription?: string;
        imageUrl?: string;
        twitterTitle?: string;
        twitterDescription?: string;
        twitterImageUrl?: string;
        keywords?: string;
    }) {
        this.title.setTitle(seoData.title);
        this.meta.updateTag({
            property: 'og:type',
            content: 'website'
        });
        this.meta.updateTag({
            name: 'og:title',
            property: 'og:title',
            content: seoData.title
        });
        this.meta.updateTag({
            name: 'description',
            property: 'description',
            content: seoData.description
        });
        this.meta.updateTag({
            property: 'og:description',
            name: 'og:description',
            content: seoData.ogDescription && seoData.ogDescription !== '' ? seoData.ogDescription : seoData.description
        });
        this.meta.updateTag({
            property: 'twitter:title',
            name: 'twitter:title',
            content: seoData.twitterTitle && seoData.twitterTitle !== '' ? seoData.twitterTitle : seoData.title
        });
        this.meta.updateTag({
            property: 'twitter:description',
            name: 'twitter:description',
            content:
                seoData.twitterDescription && seoData.twitterDescription !== ''
                    ? seoData.twitterDescription
                    : seoData.description
        });
        if (seoData.imageUrl) {
            this.meta.updateTag({
                name: 'og:image',
                property: 'og:image',
                content: seoData.imageUrl
            });
            this.meta.updateTag({
                name: 'twitter:card',
                property: 'twitter:card',
                content: 'summary_large_image'
            });
            this.meta.updateTag({
                name: 'twitter:image',
                property: 'twitter:image',
                content:
                    seoData.twitterImageUrl && seoData.twitterImageUrl !== ''
                        ? seoData.twitterImageUrl
                        : seoData.imageUrl
            });
        }
        if (seoData.keywords && seoData.keywords !== '') {
            this.meta.updateTag({
                name: 'keywords',
                property: 'keywords',
                content: seoData.keywords
            });
        }
    }

    createLinkForCanonicalURL(url?: string) {
        const existingLink = this.document.querySelector('link[rel="canonical"]');
        if (existingLink) {
            existingLink.remove();
        }
        if (url) {
            const link: HTMLLinkElement = this.document.createElement('link');
            link.setAttribute('rel', 'canonical');
            this.document.head.appendChild(link);
            link.setAttribute('href', url);
        }
    }
}
